<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading">
            <div v-if="$havePermission('CREATE_USER_MANUAL')" class="demo-spacing-0 d-flex justify-content-between m-1"
                 dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" :to="'/how-to-use/new'">
                    <feather-icon size="17" icon="HelpCircleIcon"/>
                    ساخت راهنمای جدید
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="false"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        :sort-desc.sync="isSortDirDesc"
                        style="white-space:nowrap;"
                >

                    <template #cell(content)="{item}">
                        <a class="btn btn-success cursor-pointer mb-0" @click="getHtml(item.id)">مشاهده فایل</a>
                    </template>

                    <template #cell(action)="{item}">
                        <template
                                v-if="$havePermission('UPDATE_USER_MANUAL') ||
                                 $havePermission('DELETE_USER_MANUAL')"
                        >
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item v-if="$havePermission('UPDATE_USER_MANUAL')"
                                                 :to="{name:'ShowHelp',params:{id:item.id}}">
                                    <feather-icon icon="HelpCircleIcon"/>
                                    <span class="align-middle ml-50">مشاهده و تغییر راهنما</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('DELETE_USER_MANUAL')" @click="removeData(item.id)">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">حذف راهنما</span>
                                </b-dropdown-item>

                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>
            <b-form-group v-if="html" class="p-1 m-1 border" label="نمونه خروجی فایل راهنما">
                <froalaView v-model="html"/>
            </b-form-group>
        </b-overlay>
    </b-card>
</template>

<script>

    import {
        // BPagination,
        BCard,
        BButton,
        BTable,
        // BFormTextarea,
        BDropdown,
        BDropdownItem,
        BFormGroup,
        BOverlay,
    } from 'bootstrap-vue'
    import NotAllowed from "@/layouts/components/NotAllowed";

    export default {
        name: 'HowToUse',
        components: {
            NotAllowed,
            BCard,
            BButton,
            BTable,
            // BFormTextarea,
            BDropdown,
            BDropdownItem,
            BFormGroup,
            BOverlay
        },
        data: () => ({
            dir: false,
            isSortDirDesc: '',
            sortBy: '',
            modal: false,
            isFile: 'editor',
            html:'',
            HelpData: {
                title: '',
                file: {},
                video: '',
                html: '',
            },
            editorConfig: {
                theme: 'snow',
            },
            show: [],
            isActive: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            columns: [
                {
                    label: 'عنوان راهنما',
                    key: 'title',
                    sortable: true,
                },
                // {
                //     label: 'تاریخ ایجاد',
                //     key: 'createdAtDateTime',
                //     sortable: true,
                // },
                {
                    label: 'فایل راهنما',
                    key: 'content',
                    sortable: true,
                },
                // {
                //     label: 'فایل راهنما',
                //     key: 'file',
                //     sortable: true,
                // },
                {
                    label: 'عملیات',
                    key: 'action',
                },
            ],
        }),
        methods: {
            submit() {
                this.$refs['msg-modal'].toggle();
            },
            async getData() {
                this.state.loading = true
                const res = await this.$axios.get('/pages',{
                    params : {
                        name : "HELP"
                    }
                })
                this.items = res.data.data
            },
            async getHtml(id){
                let item = this.items.find(i => i.id === id)
                this.html = item.content
            },
            async removeData(id){
                this.state.loading = true
                await this.$axios.delete('/pages/' + id)
                await this.getData()
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style lang="scss">

    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
